/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import Note from './note';
import Html from './html';
import File from './file';
import Form from './form';
import AddressBook from './addressbook';
import Rasci from './rasci';
import Level from './level';
import GeneralService from '../../services/general';
import { Button, Typography } from '../ui/core';
import { KeyBoardArrowUp } from '../ui/coreIcons'
import { Context } from '../../AppContext';

export default function LevelElements(props) {
    const context = useContext(Context);
    const [loaded, setLoaded] = useState(false)
    const [tileAlignment, setTileAlignment] = useState();
    const [index, setIndex] = useState([])
    const [levels, setLevels] = useState();
    const [notes, setNotes] = useState();
    const [html, setHtml] = useState();
    const [files, setFiles] = useState();
    const [forms, setForms] = useState();
    const [addressbook, setAddressBook] = useState();
    const [rasci,setRasci] = useState();

    let sort = '123';
    if (props.dirInfo && props.dirInfo.Sort) {
        sort = props.dirInfo.Sort;
    }
    let showIndex = (props.dirInfo && props.dirInfo.showIndex);

    const scrollToView = (id) => {
        var element = document.getElementById(id);
        if (element) {
            element.scrollIntoView(true);
          //  window.scrollBy( 0,-120); 
        }
    }

    useEffect(() => { 
        if (levels && notes && html && files && forms && addressbook) {
            setLoaded(true);
            //fix in screen updating
            window.scrollBy( 0,1); 
            window.scrollBy( 0,-1); 
        }
    }, [levels, notes, html, files, forms, addressbook])

    useEffect(() => {
        if (context && context.info && context.info.Settings && context.info.Settings.TileStyle) {
            setTileAlignment(context.info.Settings.TileStyle);
        } else {
            setTileAlignment('center')
        }
    }, [context])

    useEffect(() => {
        let index = [];

        const filteredIndex = props.index.filter((item) => { return item.HPath === props.currentHPath });

        //notes
        let tmpNotes = [];
        const fileteredNotes = filteredIndex.filter((item) => { return item.Extention === 'note' });
        for (let f = 0; f < fileteredNotes.length; f++) {
            index.push({ name: fileteredNotes[f].Name.replace('.' + fileteredNotes[f].Extention, ''), id: 'note' + fileteredNotes[f].DPath })
            tmpNotes.push(<span id={'note' + fileteredNotes[f].DPath} key={'note_bm' + fileteredNotes[f].DPath}></span>)
            if (showIndex) {
                tmpNotes.push(<Typography component="h2" className="padding" key={'note_h1' + fileteredNotes[f].DPath}   >{fileteredHtmls[f].Name.replace('.html', '')}</Typography>)
            }
            tmpNotes.push(<Note key={'note' + fileteredNotes[f].DPath} id={fileteredNotes[f].DPath} file={fileteredNotes[f]} />)
        }
        setNotes(tmpNotes);

        //html textblocks
        let tmpHtml = [];
        const fileteredHtmls = filteredIndex.filter((item) => { return item.Extention === 'html' || item.Extention === 'htm' });
        for (let f = 0; f < fileteredHtmls.length; f++) {
            index.push({ name: fileteredHtmls[f].Name.replace('.' + fileteredHtmls[f].Extention, ''), id: 'html' + fileteredHtmls[f].DPath })
            tmpHtml.push(<span id={'html' + fileteredHtmls[f].DPath} key={'html_bm' + fileteredHtmls[f].DPath}></span>);
            const hasToggle = (props.dirInfo && props.dirInfo.toggleTextBlock && props.dirInfo.toggleTextBlock.indexOf(fileteredHtmls[f].Name) >= 0);
           
            if (showIndex && !hasToggle) {
                tmpHtml.push(<Typography component="h1" className="padding" key={'html_h1' + fileteredHtmls[f].DPath}>{fileteredHtmls[f].Name.replace('.html', '')}</Typography>)
            }
            tmpHtml.push(<Html key={'html' + fileteredHtmls[f].DPath} id={fileteredHtmls[f].DPath} file={fileteredHtmls[f]} dirInfo={props.dirInfo} {...props} />)
        }
        setHtml(tmpHtml);

        //levels
        let tmpLevels = [];
        for (let l = 0; l < props.levels.length; l++) {
            if (props.levels[l].Path.indexOf(props.currentPath) === 0 && (props.currentPath.split('/').length + 1 === props.levels[l].Path.split('/').length)) {
                const compareLevel = props.levels[l].Path.substr(0, props.levels[l].Path.length - 1);
                const hideLevel = (props.dirInfo && props.dirInfo.hideLevels && props.dirInfo.hideLevels.indexOf(compareLevel) >= 0) || false;
                if (hideLevel === false) {
                    tmpLevels.push(<Level key={'level' + props.levels[l].HPath} {...props} level={props.levels[l]} />)
                }
            }
        }
        setLevels(tmpLevels);

         //rascii
         let tmpRasci = [];
         const filteredRasci = filteredIndex.filter((item) => { return item.Name === 'rasci.json' });
 
         if (filteredRasci.length > 0) {
             index.push({ name: 'Rasci', id: 'rasci' })
             tmpRasci.push(<span id={'rasci'} key={'rasci'}></span>)
         }
 
         for (let f = 0; f < filteredRasci.length; f++) {
            tmpRasci.push(<Rasci key={'rasci' + filteredRasci[f].DPath} id={filteredRasci[f].DPath} file={filteredRasci[f]} dirInfo={props.dirInfo} {...props} />)
         }
        
         setRasci(tmpRasci);


        //forms
        let tmpForms = [];
        const fileteredForms = filteredIndex.filter((item) => { return item.Extention === 'form' });

        if (fileteredForms.length > 0) {
            index.push({ name: 'Formulieren', id: 'forms' })
            tmpForms.push(<span id={'forms'} key={'forms'}></span>)
        }


        for (let f = 0; f < fileteredForms.length; f++) {
            //  index.push({ name: fileteredForms[f].Name.replace('.' + fileteredForms[f].Extention, ''), id: 'form' + fileteredForms[f].DPath })
            //   tmpForms.push(<span id={'form' + fileteredForms[f].DPath}  key={'form_bm' + fileteredForms[f].DPath}></span>)
            tmpForms.push(<Form key={'form' + fileteredForms[f].DPath} id={fileteredForms[f].DPath} file={fileteredForms[f]} />)
        }
        setForms(tmpForms);


        //addressbook
        let tmpAddressbook = [];
        const filteredAddressbook = filteredIndex.filter((item) => { return item.Name === 'adresboek.json' });

        if (filteredAddressbook.length > 0) {
            index.push({ name: 'Adreslijst', id: 'adresbook' })
            tmpAddressbook.push(<span id={'adresbook'} key={'adresbook'}></span>)
        }

        for (let f = 0; f < filteredAddressbook.length; f++) {
            tmpAddressbook.push(<AddressBook key={'adresbook' + filteredAddressbook[f].DPath} id={filteredAddressbook[f].DPath} file={filteredAddressbook[f]} dirInfo={props.dirInfo} />)
        }
        setAddressBook(tmpAddressbook);

        //files
        let tmpFiles = [];
        let filteredFiles = filteredIndex.filter((item) => { return item.Extention !== 'html' && item.Extention !== 'note' && item.Extention !== 'json' && item.Extention !== 'form' });

        filteredFiles = GeneralService.sortJSON(filteredFiles, 'Name', sort);
        let hasFiles = false;
        for (let f = 0; f < filteredFiles.length; f++) {
            const hideFiles = (props.dirInfo && props.dirInfo.hideFiles && props.dirInfo.hideFiles.indexOf(filteredFiles[f].Name) >= 0);
            if (!hideFiles) {
                hasFiles = true;
                tmpFiles.push(<File key={'file' + filteredFiles[f].DPath} id={filteredFiles[f].DPath} file={filteredFiles[f]} {...props} />)
            }
        }
        if (hasFiles) {
            index.push({ name: 'Documenten', id: 'files' })
            tmpFiles.push(<span id={'files'} key={'files'}></span>)
        }
        setFiles(tmpFiles);



        if (props.dirInfo && props.dirInfo.showIndex === true) {
            //todo create index elements
            setIndex(index);
        }
    }, [props.currentPath, props.currentHPath, props.levels, props.index])

    //<div  id={'index'} >
    return (
        <div  id={'index'} >
            {loaded && <>
                {showIndex && index.length > 0 && <div className="padding">
                    <Typography component="h1" >Inhoudsopgave</Typography>
                    <div style={{ paddingLeft: '20px' }}>
                        {index.map(item => {
                            return <div key={item.id} className="indexlink" onClick={() => { scrollToView(item.id) }}>{item.name}</div>
                        })}
                    </div>
                </div>}
                {html}
                {showIndex && index.length > 0 && <div onClick={() => { scrollToView('index') }} style={{ float: 'right', paddingRight: '8px', color: 'gray', cursor: 'pointer', clear: 'both' }}><Button variant="text" endIcon={<KeyBoardArrowUp />}>terug naar inhoudsopgave</Button></div>}
                {notes}
                {rasci} 
                {tileAlignment && <div style={{ textAlign: tileAlignment }}>{levels}</div>}
                {forms}
                {addressbook}
                {files}
              
            </>}
        </div>
    )
}
