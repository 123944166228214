
const Service = {
    postForm: (path, params, method) => {
        method = method || 'post';
        var form = document.createElement('form');
        form.setAttribute('method', method);
        form.setAttribute('action', path);

        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                var hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);

                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
    },
    simpleHash: (text) => {
        var bytes = [];
        for (var i = 0; i < text.length; i++) {
            bytes.push(text.charCodeAt(i));
        }
        return bytes.join('');
    },
    sortJSON :  (data, key, way) => {
        return data.sort( (a, b)=> {
            var x = a[key]; var y = b[key];
            if (way === '123') { return ((x < y) ? -1 : ((x > y) ? 1 : 0)); }
            if (way === '321') { return ((x > y) ? -1 : ((x < y) ? 1 : 0)); }
            return 0;
        });
    },
    toJSONLocal : (date) => {
        var local = new Date(date);
        local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return local.toJSON().slice(0, 10);
    },
    formatDate: function (d, showTime) {
        if(!d){
            return '-';
        }else{
            var givendate = new Date(d);
            var today = new Date();
            var compairedate = new Date(d);
            if (compairedate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
                if (showTime === undefined || showTime === true) {
                    return "vandaag " + ("0" + givendate.getHours()).slice(-2) + ":" + ("0" + givendate.getMinutes()).slice(-2);
                } else {
                    return "vandaag";
                }
            } else {
                var month = '';
                switch (givendate.getMonth()) {
                    case 0:
                        month = 'jan.';
                        break;
                    case 1:
                        month = 'febr.';
                        break;
                    case 2:
                        month = 'mrt.';
                        break;
                    case 3:
                        month = 'apr.';
                        break;
                    case 4:
                        month = 'mei';
                        break;
                    case 5:
                        month = 'jun.';
                        break;
                    case 6:
                        month = 'jul.';
                        break;
                    case 7:
                        month = 'aug.';
                        break;
                    case 8:
                        month = 'sept.';
                        break;
                    case 9:
                        month = 'okt.';
                        break;
                    case 10:
                        month = 'nov.';
                        break;
                    case 11:
                        month = 'dec.';
                        break;
                    default:
                        month = '';
                        break;
                }
                if( isNaN(givendate.getDate())){
                    return '';
                }else{
                    if (showTime === undefined || showTime === true) {
                        return ("0" + givendate.getDate()).slice(-2) + " " + month + " " + givendate.getFullYear() + " " + ("0" + givendate.getHours()).slice(-2) + ":" + ("0" + givendate.getMinutes()).slice(-2);
                    } else {
                        return ("0" + givendate.getDate()).slice(-2) + " " + month + " " + givendate.getFullYear();
                    }
                }
            }
        }
       
    },
    processTags : (text, imagelist) => {
        if (text !== undefined) {
            text = text.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;");
            var countstart = Service.countOcurrences(text, '[h1]');
            var countend = Service.countOcurrences(text, '[/h1]');
            if (countstart === countend) {
                text = text.replace(/\[h1\]/g, '<h1>');
                text = text.replace(/\[\/h1\]/g, '</h1>');
            }

            //bold text
            countstart = Service.countOcurrences(text, '[b]');
            countend = Service.countOcurrences(text, '[/b]');
            if (countstart === countend) {
                text = text.replace(/\[b\]/g, '<b>');
                text = text.replace(/\[\/b\]/g, '</b>');
            }

            //italic text
            countstart = Service.countOcurrences(text, '[i]');
            countend = Service.countOcurrences(text, '[/i]');
            if (countstart === countend) {
                text = text.replace(/\[i\]/g, '<i>');
                text = text.replace(/\[\/i\]/g, '</i>');
            }

            //underline text
            countstart = Service.countOcurrences(text, '[u]');
            countend = Service.countOcurrences(text, '[/u]');
            if (countstart === countend) {
                text = text.replace(/\[u\]/g, '<u>');
                text = text.replace(/\[\/u\]/g, '</u>');
            }

            //del text
            countstart = Service.countOcurrences(text, '[del]');
            countend = Service.countOcurrences(text, '[/del]');
            if (countstart === countend) {
                text = text.replace(/\[del\]/g, '<del>');
                text = text.replace(/\[\/del\]/g, '</del>');
            }


            //link
            countstart = Service.countOcurrences(text, '[a]');
            countend = Service.countOcurrences(text, '[/a]');
            if (countstart === countend) {
                text = text.replace(/\[a\]/g, '<a>');
                text = text.replace(/\[\/a\]/g, '</a>');
            }

            text = Service.processListBullitLevel2(text);
            text = Service.processListBullit(text);
            text = Service.processListNumber(text);

            text = text.replace(/\<\/ul\>\n/g, '</ul>');
            text = text.replace(/\<\/li\>\n/g, '</li>');
            text = text.replace(/\<ul\>\n/g, '<ul>');
            text = text.replace(/\<li\>\n/g, '<li>');

            text = text.replace(/\n\<\/ul\>/g, '</ul>');
            text = text.replace(/\n\<\/li\>/g, '</li>');
            text = text.replace(/\n\<ul\>/g, '<ul>');
            text = text.replace(/\n\<li\>/g, '<li>');


            text = text.replace(/\n/g, '<br/>');
            text = Service.createlink(text);
            text = Service.createImage(text, imagelist);

            return text;
        } else {
            return '';
        }
    },
    processListBullit:(content) =>{
        var contentarr = content.split('\n');
        var newcontent = '';
        var start = false;
        var end = true;
        var numberelements = 0;
        for (var i = 0; i < contentarr.length; i++) {
            if (contentarr[i].indexOf('-') === 0) {
                if (start === false) {
                    newcontent += '<ul>';
                    numberelements = 1;
                }
                start = true;
                end = false;
            }
            if (start === true) {
                if (contentarr[i].indexOf('-') === 0) {
                    if (numberelements > 1) {
                        newcontent += '</li>';
                    }
                    numberelements++;
                    newcontent += '<li>' + contentarr[i].substring(contentarr[i].indexOf('-') + 1, contentarr[i].length) + '\n';
                } else {
                    newcontent += contentarr[i] + '\n';
                }
            }
            else {
                newcontent += contentarr[i];
                if (contentarr.length - 1 !== i) {
                    newcontent += '\n';
                }
            }
            if (contentarr[i].length === 0) {
                if (end === false) {
                    newcontent += '</li></ul>\n';
                    start = false;
                    end = true;
                }
            }

        }
        return newcontent;
    },
    processListBullitLevel2:(content) =>{
        var contentarr = content.split('\n');
        var newcontent = '';
        var start = false;
        var end = true;
        var numberelements = 0;
        for (var i = 0; i < contentarr.length; i++) {
            if (contentarr[i].indexOf('--') === 0) {
                if (start === false) {
                    newcontent += '<ul>';
                    numberelements = 1;
                }
                start = true;
                end = false;
            }
            if (start === true) {
                if (contentarr[i].indexOf('--') == 0) {
                    if (numberelements > 1) {
                        newcontent += '</li>';
                    }
                    numberelements++;
                    newcontent += '<li>' + contentarr[i].substring(contentarr[i].indexOf('--') + 2, contentarr[i].length) + '\n';
                } else {
                    if (end === false) {
                        newcontent += '</li></ul>\n';
                        start = false;
                        end = true;
                    }
                    newcontent += contentarr[i] + '\n';
                }
            }
            else {
                newcontent += contentarr[i];
                if (contentarr.length - 1 !== i) {
                    newcontent += '\n';
                }
            }
        }
        return newcontent;
    },
    processListNumber:(content) =>{
        var contentarr = content.split('\n');
        var newcontent = '';
        var start = false;
        var end = true;
        var numberelements = 0;
        for (var i = 0; i < contentarr.length; i++) {
            if (contentarr[i].indexOf('1.') === 0) {
                newcontent += '<ol>';
                numberelements = 1;
                start = true;
                end = false;
            }
            if (start === true) {
                if (!isNaN(parseInt(contentarr[i].split('\s')[0]))) {
                    if (numberelements > 1) {
                        newcontent += '</li>';
                    }
                    numberelements++;
                    newcontent += '<li>' + contentarr[i].substring(contentarr[i].indexOf('.') + 1, contentarr[i].length) + '\n';
                } else {
                    newcontent += contentarr[i] + '\n';
                }
            } else {
                newcontent += contentarr[i];
                if (contentarr.length - 1 !== i) {
                    newcontent += '\n';
                }
            }
            if (contentarr[i].length === 0) {
                if (end === false) {
                    newcontent += '</li></ol>';
                    start = false;
                    end = true;
                }
            }
        }
        return newcontent;
    },
    countOcurrences:(str, value) =>{
        if (str !== undefined) {
            return str.split(value).length - 1;
        } else {
            return 0;
        }
    },
    createlink:(text) =>{
       
        var urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;

        // Email addresses
        var emailAddressPattern = /[\w-&.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;

        var result = "";
        var system = "ios";
        try {
            //ToDo : detect platform
            //if (device !== undefined) {
            //    system = device.platform;
            //}
        } catch (ex) {
        }
        system = system.toLowerCase();
        if (system === "ios") {
            result = text.replace(urlPattern, '<a href=\'$&\' target=\'_blank\'>$&</a>').replace('>http://', '>').replace('>https://', '>').replace(emailAddressPattern, '<a href="mailto:$&">$&</a>');
        } else {
            result = text.replace(urlPattern, '<a href=\'$&\' target=\'_system\'>$&</a>').replace('>http://', '>').replace('>https://', '>').replace(emailAddressPattern, '<a href="mailto:$&">$&</a>');
        }

        return result;
    },
    createImage:(text, imagelist)=> {
        if (imagelist && imagelist.length>0) {
            var imgPattern = /\[img\](.*)\[\/img\]/gim;
            
            var matches = text.replace(/\[img\]/g,'\r\n[img]').match(imgPattern);
            if(matches){
                for (var i = 0; i < matches.length; i++) {
                    text = text.replace(matches[i], '<img class="' + imagelist[matches[i].replace('[img]', '').replace('[/img]', '')] + '"/>')
                }
            }
        }
        return text
    },
    getTextVerticle: (text, fontSize) => {
        var c = document.createElement("CANVAS");
        var ctx = c.getContext("2d");
        var font, text, x, y;
        font = fontSize;
        ctx.font = font + 'px Roboto,Helvetica Neue,sans-serif';
        var metrics = ctx.measureText(text);
        c.width = font;//The height of the text. The text will be sideways.
        c.height = metrics.width;//The measured width of the text
        //After a canvas resize, the context is reset. Set the font size again
        ctx.font = font + 'px Roboto,Helvetica Neue,sans-serif';
        //Set the drawing coordinates
        x = font / 2;
        y = metrics.width / 2;
        //Style
        ctx.fillStyle = 'black';
        ctx.textAlign = 'left';
        ctx.textBaseline = "bottom";
        //Rotate the context and draw the text
        ctx.save();
        ctx.translate(x , y + (y));
        ctx.rotate(-Math.PI / 2);
        ctx.fillText(text, 0, font / 2);
        ctx.restore();
        return c.toDataURL();
    }


}
export default Service;

