import React,{useEffect, useState} from 'react';

const ViewProcessGeneral = ({ process, rasci }) => {
    const [chapter, setChapter] = useState();
    const [paragraph, setParagraph] = useState();

    useEffect(() => {
        if (process && rasci) {
            let foundParagraph = rasci.processes.find(pro => { return pro._id === process.parent });
            if (foundParagraph) {
                setParagraph(foundParagraph.number + ' ' + foundParagraph.name);
                let foundChapter = rasci.processes.find(pro => { return pro._id === foundParagraph.parent });
           
                if (foundChapter) setChapter(foundChapter.number + ' ' + foundChapter.name);
            }           
        }
    }, [process])

    const getProcessText  = (process) => {
        switch (process) {
            case 'P': return 'P - Plan';
            case 'D': return 'D - Do';
            case 'C': return 'C - Check';
            case 'A': return 'A - Act';
            case 'W': return 'W - Wet';
            case 'K': return 'K - Kader';
            default: return '';
        }
    }
    if (!process) return null;
    return (
        <div className='padding'>
            <div className='label'>Toelichting</div>
            {process.note} <br/><br/>
            <div className='label'>Type process</div>
            {getProcessText(process.pdca)}<br/><br/>
            <div className='label'>Hoofdstuk maturitymodel</div>
            {chapter}<br/><br/>
            <div className='label'>Paragraaf maturitymodel</div>
            {paragraph}<br/><br/>
            {process.link && <>
                <div className='label'>Hyperlink naar externe omgeving</div>
                <a href={process.link} target='_blank'>{process.link}</a><br/><br/>
            </>}

        </div>
    );
};

export default ViewProcessGeneral;