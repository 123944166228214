import API from "./api";
const Service = {
    get: (rasciID,table, id) => {
        return new Promise((resolve, reject) => {
            let url = 'rasci/' + rasciID + '/' + table;
            if (id) url += '/' + id;
            API.get(url).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (rasciID,table, item) => {
        return new Promise((resolve, reject) => {
            API.post('rasci/' + rasciID + '/' + table, null, { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    formatRasciLetter: (rasci) => {
        switch(rasci){
            case 'R':
                return 'R- Responsible';
            case 'A':
                return 'A - Accountable';
            case 'S':
                return 'S - Support';
            case 'C':
                return 'C - Consulted';
            case 'I':
                return 'I - Informed';
            default:
                return ''; 
        }
    }
}
export default Service;