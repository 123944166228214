import React from 'react'

const ListItem = function ListItem(props) {
    const style = props.style || {};
    if(props.compact){
        style.height = 'auto';
    }
    return (
        <div className={'listitem'} style={style}  onClick={(ev)=>{ev.stopPropagation();if (props.onClick) props.onClick()}}>{props.children}</div>
    )
}

export { ListItem };
