import React, { useState } from 'react';
import ServiceRasci from '../../../services-admin/rasci';
import ServiceGeneral from '../../../services/general';
import { Button } from '../../ui/button';
import { List } from '../../ui/list';
import { ListItem } from '../../ui/listItem';
import { ListItemText } from '../../ui/listItemText';
import { Dialog } from '../../ui/dialog';
import { DialogTitle } from '../../ui/dialogTitle';
import { DialogContent } from '../../ui/dialogContent';
import { DialogActions } from '../../ui/dialogActions';
import { Typography } from '../../ui/typography';
import { TextField } from '../../ui/textField';
import { Select } from '../../ui/select';
import { Delete } from '../../ui/coreIcons';
const EditProcesses = ({ rasciID }) => {
    const table = 'processes';
    const [items, setItems] = useState([]);
    const [editItem, setEditItem] = useState();
    const [functions, setFunctions] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [linkedActivities, setLinkedActivities] = useState([]);

    const [newTask, setNewTask] = useState();
    const [newFunction, setNewFunction] = useState();
    const [newRasci, setNewRasci] = useState();

    const loadItems = () => {
        ServiceRasci.get(rasciID, table)
            .then((result) => {
                setItems(ServiceGeneral.sortJSON(result, 'number', '123'));
            }, (err) => {
                console.log(err);
            })
        ServiceRasci.get(rasciID, "tasks")
            .then((result) => {
                setTasks(ServiceGeneral.sortJSON(result, 'number', '123'));
            }, (err) => {
                console.log(err);
            })
        ServiceRasci.get(rasciID, "functions")
            .then((result) => {
             
                setFunctions(ServiceGeneral.sortJSON(result, 'number', '123'));
            }, (err) => {
                console.log(err);
            })
        ServiceRasci.get(rasciID, "linkedActivities")
            .then((result) => {
                setLinkedActivities(result);
            }, (err) => {
                console.log(err);
            })
    }

    const handleAddItem = () => {
        ServiceRasci.update(rasciID, table, { rasciID: rasciID, name: 'Nieuw proces', level: 0, active: true }).
            then((result) => {
                setEditItem(result[0]);
                loadItems();
            }, (err) => {
                console.log(err);
            })
    }

    useState(() => {
        loadItems();
    }, [rasciID]);

    const handleChange = (attribute, value, save) => {

        setEditItem({ ...editItem, [attribute]: value });
        if (save) handleSave({ ...editItem, [attribute]: value });
    }

    const handleSave = (item) => {
        ServiceRasci.update(rasciID, table, item)
            .then((result) => {
                loadItems();
            });
    }


    const handleSaveLinkedItem = () => {
        let newLinkedItem = {};
        newLinkedItem.rasciID = rasciID;
        newLinkedItem.processID = editItem._id;
        newLinkedItem.taskID = newTask;
        newLinkedItem.functionID = newFunction;
        newLinkedItem.rasci = newRasci;
        newLinkedItem.active = true;
        console.log(newLinkedItem)

        ServiceRasci.update(rasciID, "linkedActivities", newLinkedItem)
            .then((result) => {
                loadItems();
            });

    }

    const handleDelete = () => {
        if (window.confirm('Weet je zeker dat je dit proces wilt verwijderen? Alle onderliggende items zijn dan ook niet meer zichtbaar.')) {
            ServiceRasci.update(rasciID, table, { ...editItem, active: false })
                .then((result) => {
                    loadItems();
                    setEditItem(null);
                });
        }
    }

    const handleDeleteLinkedItem = (item) => {
        if (window.confirm('Weet je zeker dat je deze activiteit wilt verwijderen?')) {
            let updateItem = { ...item, active: false };
            ServiceRasci.update(rasciID, "linkedActivities", updateItem)
                .then((result) => {
                    loadItems();
                });
        }
    }
 
    if(linkedActivities && items){

     //   console.log(linkedActivities, editItem._id,linkedActivities.filter(item => { return item.processID == editItem._id }));
    }
    return (
        <div>
            {editItem && <Dialog fullScreen onClose={() => { }} className="padding-ios">
                <DialogTitle><Typography component="h2">Proces</Typography></DialogTitle>
                <DialogContent >
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <TextField id="number" value={editItem.number} label="Nummer " onChange={(ev) => handleChange('number', ev.target.value)} onBlur={() => handleSave(editItem)} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <TextField id="name" value={editItem.name} label="Naamgeving" onChange={(ev) => handleChange('name', ev.target.value)} onBlur={() => handleSave(editItem)} />
                        </div>
                        {editItem.level == 0 && <div style={{ width: '100%' }}>
                            <TextField id="short" value={editItem.short} label="Afkorting" onChange={(ev) => handleChange('short', ev.target.value)} onBlur={() => handleSave(editItem)} />
                        </div>}
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <Select value={editItem.level} label="Indeling" onChange={(ev) => { handleChange('level', parseInt(ev.target.value), true) }}>

                                <option value={0}>Hoofdstuk</option>
                                <option value={1}>Paragraaf</option>
                                <option value={2}>Product</option>
                            </Select>
                        </div>
                        {editItem.level !== undefined && (editItem.level == "1" || editItem.level == 2) && <div style={{ width: '100%' }}>
                            <Select value={editItem.parent} label="Bovenliggend indeling" onChange={(ev) => { handleChange('parent', ev.target.value, true) }}>
                                <option value={undefined}>-</option>
                                {editItem.level == 1 && items.filter(item => { return item.level == 0 }).map((item) => { return <option key={item._id} value={item._id}>{(item.number || '') + ' ' + item.name}</option> })}
                                {editItem.level == 2 && items.filter(item => { return item.level == 1 }).map((item) => { return <option key={item._id} value={item._id}>{(item.number || '') + ' ' + item.name}</option> })}

                            </Select>
                        </div>}
                    </div>

                    {editItem.level !== undefined && editItem.level == 2 && <>
                        <Select value={editItem.pdca} label="PDCA" onChange={(ev) => { handleChange('pdca', ev.target.value, true) }}>
                            <option value={undefined}>-</option>
                            <option value={"W"}>W - Wet </option>
                            <option value={"K"}>K - Kader</option>
                            <option value={"P"}>P - Plan</option>
                            <option value={"D"}>D - Do</option>
                            <option value={"C"}>C - Check</option>
                            <option value={"A"}>A - Act</option>
                        </Select>
                    </>}
                    <TextField id="link" value={editItem.link} label="Hyperlink naar externe omgeving" onChange={(ev) => handleChange('link', ev.target.value)} onBlur={() => handleSave(editItem)} />

                    <TextField id="note" value={editItem.note} label="Toelichting" onChange={(ev) => handleChange('note', ev.target.value)} onBlur={() => handleSave(editItem)} />

                    <br />  <br />
                    {editItem.level == 2 && <>
                        <div className='label'>Activiteiten</div>


                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th>Taak</th>
                                    <th>Functie</th>
                                    <th>RASCI</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {linkedActivities.filter(item => { return item.processID == editItem._id }).map((item) => {
                  
                                    return (
                                        <tr key={item._id}  >
                                            <td  >
                                                {tasks.filter(task => { return task._id == item.taskID }).map((task) => { return (task.number || '') + ' ' + task.name })}
                                            </td>
                                            <td >
                                                {functions.filter(funct => { return funct._id == item.functionID }).map((funct) => { return (funct.number || '') + ' ' + funct.name })}
                                            </td>
                                            <td >
                                                {ServiceRasci.formatRasciLetter(item.rasci)}
                                            </td>
                                            <td>
                                                <Button variant="contained" onClick={() => { handleDeleteLinkedItem(item) }}> <Delete color="white" /> </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr><td colSpan={4}><br /><br /></td></tr>
                                <tr><td colSpan={4}>Toevoegen:</td></tr>
                                <tr>
                                    <td> <Select value={newTask} onChange={(ev) => { setNewTask(ev.target.value) }}>
                                        <option value={undefined}></option>
                                        {tasks.map((item) => { return <option key={item._id} value={item._id}>{(item.number || '') + ' ' + item.name}</option> })}
                                    </Select>
                                    </td>
                                    <td>   <Select value={newFunction} onChange={(ev) => { setNewFunction(ev.target.value) }}>
                                        <option value={undefined}></option>
                                        {functions.map((item) => { return <option key={item._id} value={item._id}>{(item.number || '') + ' ' + item.name}</option> })}
                                    </Select>
                                    </td>
                                    <td>  <Select value={newRasci} onChange={(ev) => { setNewRasci(ev.target.value) }}>
                                        <option value={undefined}></option>
                                        <option value={"R"}>R - Responsible </option>
                                        <option value={"A"}>A - Accountable</option>
                                        <option value={"S"}>S - Support</option>
                                        <option value={"C"}>C - Consulted</option>
                                        <option value={"I"}>I - Informed</option>
                                    </Select>
                                    </td>
                                    <td>
                                        <Button variant="contained" onClick={handleSaveLinkedItem} disabled={(!newTask || !newFunction || !newRasci)}>TOEVOEGEN </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>



                    </>}

                </DialogContent>
                <DialogActions>
                    <Button variant="text" style={{ color: 'red', float: 'left' }} onClick={() => { handleDelete() }}>Verwijderen</Button>
                    <Button variant="outlined" onClick={() => { setEditItem(null) }}>Sluiten</Button>
                </DialogActions>
            </Dialog>}

            <Button style={{ float: 'right' }} onClick={handleAddItem}>TOEVOEGEN</Button>
            <List>
                {items.filter(item => { return item.level == 0 }).map((level) => {
                    let arrItems = [];
                    arrItems.push(<ListItem key={level._id} onClick={() => { setEditItem(level) }} compact={true}>
                        <ListItemText primary={(level.number || '') + ' ' + level.name} style={{fontWeight:'bold',fontSize:'larger',backgroundColor:'silver'}}/>
                    </ListItem>)
                    items.filter(item => { return item.parent == level._id }).map((sublevel) => {
                        arrItems.push(
                            <ListItem key={sublevel._id} onClick={() => { setEditItem(sublevel) }} compact={true}>
                                <ListItemText primary={(sublevel.number || '') + ' ' + sublevel.name} style={{ paddingLeft: '30px',fontWeight:'bold' ,backgroundColor:'#e8e8e8'}} />
                            </ListItem>
                        )
                        items.filter(item => { return item.parent == sublevel._id }).map((product) => {
                            arrItems.push(
                                <ListItem key={product._id} onClick={() => { setEditItem(product) }} compact={true}>
                                    <ListItemText primary={(product.number || '') + ' ' + product.name} style={{ paddingLeft: '55px' }} />
                                </ListItem>
                            )
                        })
                    })
                    return arrItems;
                })}
            </List>
        </div>
    );
};

export default EditProcesses;