import React, { useState, useEffect, useContext } from 'react'
import ServiceFiles from "../../../services-admin/files";
import ServiceRasci from '../../../services-admin/rasci';
import { Context } from '../../../AppContext';
import { Dialog, DialogContent, DialogActions, DialogTitle, Button, Typography } from '../../ui/core';
import ServiceGeneral from '../../../services/general';
import { Tabs } from '../../ui/tabs';
import EditFunctions from './edit-functions';
import EditProcesses from './edit-processes';
import EditOrganisaties from './edit-organisations';
import EditTasks from './edit-tasks';
import EditGeneral from './edit-general';
import EditEducations from './edit-educations';
import EditMeetings from './edit-meetings';
export default function RASCI(props) {
    const context = useContext(Context);
    const [id, setID] = useState();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [item,setItem] = useState();

    useEffect(() => {
        ServiceFiles.getFileContent(props.version, props.access, props.level, props.file.filename)
            .then((result) => { 
                if (result) {
                    try {
                        setID(result._id);
                        setItem(result); 
                    } catch (ex) {
                        console.log(ex);
                        props.onClose();
                    }
                }
            }, (err) => {
                console.log(err);
                props.onClose();
            })
    }, [props.file])

    const handlePublish = () => {
        let tmpItem = Object.assign({},item);
     ///   tmpItem._id = id;
        let arrPromises = [];
        arrPromises.push(ServiceRasci.get(id, 'organisations'));
        arrPromises.push(ServiceRasci.get(id, 'functions'));
        arrPromises.push(ServiceRasci.get(id, 'tasks'));
        arrPromises.push(ServiceRasci.get(id, 'processes'));
        arrPromises.push(ServiceRasci.get(id, 'linkedActivities'));
        arrPromises.push(ServiceRasci.get(id, 'meetings'));
        arrPromises.push(ServiceRasci.get(id, 'educations'));
        Promise.all(arrPromises).then((result) => {
            tmpItem.organisations = ServiceGeneral.sortJSON(result[0],'number','123');
            tmpItem.functions = ServiceGeneral.sortJSON(result[1],'number','123');
            tmpItem.tasks = ServiceGeneral.sortJSON(result[2],'number','123');
            tmpItem.processes = ServiceGeneral.sortJSON(result[3],'number','123');
            tmpItem.linkedActivities = result[4]; 
            tmpItem.meetings = ServiceGeneral.sortJSON(result[5],'number','123'); 
            tmpItem.educations = ServiceGeneral.sortJSON(result[6],'number','123');
            ServiceFiles.saveBase64(props.version, props.access, props.level, props.file.filename, ServiceFiles.encodeUnicode(JSON.stringify(tmpItem)))
            .then((result) => { 
                context.setMessage('RASCI model is gepubliceerd');
                // props.onClose();
            }, (err) => {
                 props.onClose();
            })
        });
      
    }

    if (!id) return null;
    return (
        <Dialog fullScreen onClose={props.onClose} className="padding-ios">
            <DialogTitle><Typography component="h2">RASCI model</Typography></DialogTitle>
            <DialogContent style={{ padding: '0px' }}>
                <Tabs value={selectedTab} onChange={setSelectedTab}>
                    <div>ORGANISATIES</div>
                    <div>OPLEIDINGEN</div>
                    <div>OVERLEGGEN</div>
                    <div>FUNCTIES</div>
                    <div>TAKEN</div>
                    <div>PROCESSEN</div>
                    <div>INSTELLINGEN</div>
                </Tabs>
                {selectedTab === 0 && <EditOrganisaties rasciID={id} />}
                {selectedTab === 1 && <EditEducations rasciID={id} />}
                {selectedTab === 2 && <EditMeetings rasciID={id} />}
                {selectedTab === 3 && <EditFunctions rasciID={id} />}
                {selectedTab === 4 && <EditTasks rasciID={id} />}
                {selectedTab === 5 && <EditProcesses rasciID={id} />}
                {selectedTab === 6 && <EditGeneral rasciID={id} item={item} onChange={setItem}/>}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handlePublish}>Publiceren</Button>
                <Button variant="outlined" onClick={props.onClose}>Sluiten</Button>
            </DialogActions>
        </Dialog>
    )
}
