
import React, { useContext, useEffect, useState } from 'react'
import FileService from '../services/file';
import StorageService from '../services/storage';
import { Context } from "../AppContext";
import { ContextUI } from '../uiContext';
import ViewRoles from '../components/index/rasci/view-roles';
const RasciRoles = (props) => {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
    const [rasci, setRasci] = useState();

    useEffect(() => {
        contextUI.setTitle('Rollen / taakomschrijving');
        contextUI.setSubTitle('');
        contextUI.setShowBackButton(true);

        if (props.match.params.id) {
            let foundFile = context.info.Index.find(file => file.DPath === props.match.params.id);

            if (foundFile) {
                FileService.getContent(foundFile.DPath, StorageService.getStorageFileName(foundFile), true)
                    .then(content => {
                        let obj = (typeof content === 'string') ? JSON.parse(content) : content;
                        setRasci(obj);
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        }
    }, [])

    if (!rasci) return null;
    return (
        <div  >
            <ViewRoles rasci={rasci}  selected={props.match.params.roleid}  history={props.history} fileID={props.match.params.id}/>
        </div>
    )
}
export default RasciRoles
