import React from 'react'

const ListItemText = function ListItemText(props) {
    return (
        <div className={'listitemtext'} style={props.style} >
            <div className="listitemprimary">{props.primary}</div>
            {props.secondary && <><div className="listitemsecondary">{props.secondary}</div></>}
        </div>
    )
}

export { ListItemText };
