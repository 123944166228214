import React from 'react';

const TabsOrganisationsShort = ({rasci,value,onChange}) => {
    const styleTab = {borderBottom:'2px solid white',cursor:'pointer',padding:'4px'};
    const styleTabSelected = {borderBottom:'2px solid #00006a',cursor:'pointer',padding:'4px'};
    return (
        <div style={{display:'flex',width:'100%',overflow:'scroll',fontSize:'smaller',padding:'4px'}}>
            <div   style={!value?styleTabSelected:styleTab} onClick={()=>onChange(null)}>ALL</div>
            {rasci.organisations.map((org, index) => {  
                return <div key={org._id} onClick={() =>onChange&& onChange(org._id)}   style={value==org._id?styleTabSelected:styleTab}>{org.short}</div>
            })}
        </div>
    );
};

export default TabsOrganisationsShort;