import React, { useState } from 'react';
import ServiceRasci from '../../../services-admin/rasci';
import ServiceGeneral from '../../../services/general';
import { Button } from '../../ui/button';
import { List } from '../../ui/list';
import { ListItem } from '../../ui/listItem';
import { ListItemText } from '../../ui/listItemText';
import { Dialog } from '../../ui/dialog';
import { DialogTitle } from '../../ui/dialogTitle';
import { DialogContent } from '../../ui/dialogContent';
import { DialogActions } from '../../ui/dialogActions';
import { Typography } from '../../ui/typography';
import { TextField } from '../../ui/textField'; 
const EditOrganisaties = ({ rasciID }) => {
    const table = 'meetings';
    const [items, setItems] = useState([]);
    const [editItem, setEditItem] = useState();

    const loadItems = () => {
        ServiceRasci.get(rasciID, table)
            .then((result) => {
             
                setItems(ServiceGeneral.sortJSON(result,'number','123'));
            }, (err) => {
                console.log(err);
            })
    }

    const handleDelete = () => {
        if (window.confirm('Weet je zeker dat je dit overleg wilt verwijderen.')) {
            ServiceRasci.update(rasciID, table, { ...editItem, active: false })
                .then((result) => {
                    loadItems();
                    setEditItem(null);
                });
        }
    }

    const handleAddItem = () => {
        ServiceRasci.update(rasciID, table, { rasciID: rasciID, name: 'Nieuw overleg', active: true }).
            then((result) => { 
                setEditItem(result[0]);
                loadItems();
            }, (err) => {
                console.log(err);
            })
    }

    useState(() => {
        loadItems();
    }, [rasciID]);

    const handleChange = (attribute, value) => {    
        setEditItem({ ...editItem, [attribute]: value });
    }

    const handleSave = (item) => {  
        ServiceRasci.update(rasciID, table, item)
        .then((result) => {
            loadItems();
        });
      
    }
    return (
        <div>
            {editItem && <Dialog fullScreen onClose={() => { }} className="padding-ios">
                <DialogTitle><Typography component="h2">Overleggen</Typography></DialogTitle>
                <DialogContent >  
                <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <TextField id="number" value={editItem.number} label="Nummer " onChange={(ev) => handleChange('number', ev.target.value)} onBlur={() => handleSave(editItem)} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <TextField id="name" value={editItem.name} label="Naamgeving" onChange={(ev) => handleChange('name', ev.target.value)} onBlur={() => handleSave(editItem)} />
                        </div>
                    </div>
                    <TextField id="note" value={editItem.note} label="Toelichting" onChange={(ev) => handleChange('note', ev.target.value)} onBlur={() => handleSave(editItem)} />
                    
                        </DialogContent>
                <DialogActions>
                <Button variant="text" style={{ color: 'red', float: 'left' }} onClick={() => { handleDelete() }}>Verwijderen</Button>
                    <Button variant="outlined" onClick={() => { setEditItem(null) }}>Sluiten</Button>
                </DialogActions>
            </Dialog>}

            <Button style={{ float: 'right' }} onClick={handleAddItem}>TOEVOEGEN</Button>
            <List>
                {items.map((item) => {
                    return (
                        <ListItem key={item._id} onClick={() => { setEditItem(item) }} compact={true}>
                            <ListItemText primary={(item.number || '') + ' ' + item.name} />
                        </ListItem>
                    )
                })
                }
            </List>
        </div>
    );
};

export default EditOrganisaties;