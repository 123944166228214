import React from 'react'
//todo create tooltip
const  Tooltip = (props)=> {
    //props.title
    //props.placement  : top ; bottom
    return (
        <div className='tooltip'> {props.children}
            <span className="tooltiptext">{props.tooltip}</span>
        </div>
    )
}
export { Tooltip };