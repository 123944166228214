import React, { use, useEffect, useState } from 'react';
import { SearchBar } from '../../ui/searchBar';
import { Typography } from '../../ui/typography';
import { Tabs } from '../../ui/tabs';
import { Paper } from '../../ui/paper';
import ServiceGeneral from '../../../services/general';
import ViewRolesGeneral from './view-roles-general';
import ViewRolesProcesses from './view-roles-processes';
import ViewProcessGeneral from './view-process-general';
import TabsOrganisationsShort from './tabs-organisations-short';
import ViewProcessTasks from './view-process-tasks';
const ViewRoles = ({ rasci, selected,fileID ,history}) => {
    const [selectedFunction, setSelectedFunction] = useState();
    const [selectedView, setSelectedView] = useState(0);
    const [filteredFunctions, setfilteredFunctions] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedOrganisation, setSelectedOrganisation] = useState();
    const [selectedProcess, setSelectedProcess] = useState();

    useEffect(() => {
        if (selected && rasci.functions) {
            let funct = rasci.functions.find((p) => p._id === selected);
            if (funct) setSelectedFunction(funct);
        } else {
            if (rasci.functions && rasci.functions.length > 0) {
                setSelectedFunction(rasci.functions[0]);
            }
        }
        setfilteredFunctions(rasci.functions);
    }, [rasci.functions, selected])

    useEffect(() => {
        let tmpFunctions = Object.assign([], rasci.functions);
        if (selectedOrganisation) {
            tmpFunctions = tmpFunctions.filter((funct) => funct.organisation === selectedOrganisation);
        }
        if (searchText) {
            tmpFunctions = tmpFunctions.filter((funct) => funct.name.toLowerCase().includes(searchText.toLowerCase()));
        }
        setfilteredFunctions(ServiceGeneral.sortJSON(tmpFunctions, 'number', '123'));
    }, [searchText, selectedOrganisation])

    useEffect(() => {
        if (selectedView == 2) setSelectedView(0);
    }, [selectedFunction])

    if (!rasci) return null;
    if (!rasci.functions) return null;
    if (!selectedFunction) return null;

    const getOrganisation = (id) => {
        let org = rasci.organisations.find((o) => o._id === id);
        if (org) return org.name;
        return '';
    }

    const getProcess = (id) => {
        let process = rasci.processes.find((p) => p._id === id);
        return process;
    }

    return (
        < div className='padding'>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Paper style={{ width: '33%', margin: '8px' }}>
                    <TabsOrganisationsShort rasci={rasci} value={selectedOrganisation} onChange={setSelectedOrganisation} />
                    <SearchBar value={searchText} onKeyUp={setSearchText} onReset={() => { setSearchText(undefined) }} />
                        <div style={{height:'calc(100vh - 200px)',overflowY:'auto'}}>
                    {filteredFunctions.map((funct, index) => {
                        return (
                            <div key={index} style={{ width: 'auto', padding: '8px', margin: '8px', backgroundColor: '#f7f7f7', border: '1px solid #f7f7f7', cursor: 'pointer', borderLeft: (selectedFunction.number == funct.number) ? '2px solid #00006a' : '2px solid #f7f7f7' }} onClick={() => { setSelectedFunction(funct); setSelectedProcess(null) }}>
                                {funct.number}  {funct.name}<br />
                                <span style={{ fontSize: 'smaller' }}> <i>{getOrganisation(funct.organisation)}</i></span>
                            </div>
                        )
                    })}
                    </div>
                </Paper>
                <Paper style={{ width: '67%', margin: '8px', padding: '8px' }}>
                    <Typography component="h2" >{selectedFunction.number} {selectedFunction.name}</Typography><br />
                    <Tabs value={selectedView} onChange={setSelectedView}>
                        <div >
                            TAAKOMSCHRIJVING
                        </div>
                        <div>
                            WERKPROCESSEN/PRODUCTEN
                        </div>
                        {selectedProcess && <div>{selectedProcess.name.toUpperCase()}</div>}
                    </Tabs>
                    {selectedView === 0 && <ViewRolesGeneral funct={selectedFunction} rasci={rasci} />}

                    {selectedView === 1 && <ViewRolesProcesses funct={selectedFunction} rasci={rasci} onSelectProcess={(id) => { setSelectedProcess(getProcess(id)); setSelectedView(2) }} />}

                    {selectedView === 2 && <ViewProcessGeneral process={selectedProcess} rasci={rasci} />}

                    {selectedView === 2 && <div >
                        <div className='label padding'>Taken</div>
                        <ViewProcessTasks process={selectedProcess} rasci={rasci} history={history} fileID={fileID}/>
                    </div>}
                </Paper>
            </div>
        </div>
    );
};

export default ViewRoles;