import React from 'react';

const ViewTasksGeneral = ({ funct, rasci }) => {

    if (!funct) return null;

    return (
        <div className='padding'>
            <div className='label'>Doelstelling</div>
            {funct.targets} <br /><br />
            <div className='label'>Kader</div>
            {funct.frame}<br /><br />
            <div className='label'>Profiel</div>
            {funct.profile}<br /><br />
            <div className='label'>Opleidingen</div>
            {funct.educations && funct.educations.map  && funct.educations.map((item) => {
                let found = rasci.educations.find((element) => element._id === item);
                if (!found) return null;
                return <>{found.number} {found.name} <br /></>;
            })} <br />

            <div className='label'>Overleggen</div>
            {funct.meetings && funct.meetings.map && funct.meetings.map((item) => {
                let found = rasci.meetings.find((element) => element._id === item);
                if (!found) return null;
                return <>{found.number} {found.name} <br /></>;
            })} <br /><br />
        </div>
    );
};

export default ViewTasksGeneral;