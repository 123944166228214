import React from 'react';

const TabsPDCA = ({rasci,value,onChange}) => {
    const styleTab = {borderBottom:'2px solid white',cursor:'pointer',padding:'4px'};
    const styleTabSelected = {borderBottom:'2px solid #00006a',cursor:'pointer',padding:'4px'};
    return (
        <div style={{display:'flex',width:'100%',overflow:'scroll',fontSize:'smaller',padding:'4px'}}>
            <div   style={!value?styleTabSelected:styleTab} onClick={()=>onChange(null)}>ALL</div>
            <div   style={value=='W'?styleTabSelected:styleTab} onClick={()=>onChange('W')}>Wet</div>
            <div   style={value=='K'?styleTabSelected:styleTab} onClick={()=>onChange('K')}>Kaders</div>
            <div   style={value=='P'?styleTabSelected:styleTab} onClick={()=>onChange('P')}>Plan</div>
            <div   style={value=='D'?styleTabSelected:styleTab} onClick={()=>onChange('D')}>Do</div>
            <div   style={value=='C'?styleTabSelected:styleTab} onClick={()=>onChange('C')}>Check</div>
            <div   style={value=='A'?styleTabSelected:styleTab} onClick={()=>onChange('A')}>Act</div>
           
        </div>
    );
};

export default TabsPDCA;