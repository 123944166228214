import React, { useEffect, useState } from 'react';
 

const ViewProcessTasks = ({ process, rasci ,history,fileID}) => {
    const [linkedActivities, setLinkedActivities] = useState();
    const [foundTasks, setFoundTasks] = useState();
    useEffect(() => {
        if (process && rasci) {
            setLinkedActivities(rasci.linkedActivities.filter(ls => { return ls.processID === process._id }));


            let taskIDs = rasci.linkedActivities.filter(la => { return la.processID === process._id });
            let foundTasks = rasci.tasks.filter(t => { return taskIDs.find(la => { return la.taskID == t._id }) });
            setFoundTasks(foundTasks);

        }
    }, [process, rasci])


    const onClickTask = (task) => { 
        history && fileID && history.push(`/rasci/tasks/${fileID}/${task._id}`); 
    }

    if (!process) return null;
    if (!foundTasks) return null;
    return (
        <div  >

            {foundTasks.map((task, index) => {
                return (
                    <div key={index} onClick={()=>{onClickTask(task)}} style={{ width: 'auto', padding: '8px', margin: '8px', backgroundColor: '#f7f7f7', border: '1px solid #f7f7f7',cursor:'pointer'  }} >
                        {task.number} {task.name}
                    </div>)
            })}
        </div>
    );
};

export default ViewProcessTasks;