import React, {  useEffect, useState } from 'react';
import { SearchBar } from '../../ui/searchBar';
import { Typography } from '../../ui/typography';
import { Tabs } from '../../ui/tabs';
import { Paper } from '../../ui/paper';
import ServiceGeneral from '../../../services/general';
import ViewRolesGeneral from './view-roles-general'; 
import ViewTaskGeneral from './view-tasks-general';
import ViewTasksFunctions from './view-tasks-functions';
import { Button } from '../../ui/button';
const ViewTasks = ({ rasci ,selected,fileID ,history}) => {
    const [selectedTask, setSelectedTask] = useState();
    const [selectedView, setSelectedView] = useState(0);
    const [selectedFunction, setSelectedFunction] = useState();
    const [filteredTasks, setfilteredTasks] = useState([]);
    const [searchText, setSearchText] = useState(''); 
    const [selectedProcess, setSelectedProcess] = useState();
    
    useEffect(() => {
       if(selected && rasci.tasks){
        let task = rasci.tasks.find((p) => p._id === selected);
        if(task) setSelectedTask(task);
       }else{
        if (rasci.tasks && rasci.tasks.length > 0) {
            setSelectedTask(rasci.tasks[0]);
        }
        setfilteredTasks(rasci.tasks);
       }      
    }, [rasci.tasks,selected])

    useEffect(() => {
        let tmpTasks = Object.assign([], rasci.tasks);
     
        if (searchText) {
            tmpTasks = tmpTasks.filter((funct) => funct.name.toLowerCase().includes(searchText.toLowerCase()));
        }
        setfilteredTasks(ServiceGeneral.sortJSON(tmpTasks, 'number', '123'));
    }, [searchText])

    useEffect(() => {
       if(selectedView==2) setSelectedView(0);
    }, [selectedTask])


    useEffect(() => {
        if(selectedView==2) {
            setSelectedView(0);
        
        }
        setSelectedFunction(null);
     }, [selectedTask])
 
    const getFunction = (id) => {
        let funct = rasci.functions.find((p) => p._id === id);
       
        return funct;
    }

    if (!rasci) return null;
    if (!rasci.functions) return null;
    if (!selectedTask) return null;

    return (
        < div className='padding'>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Paper style={{ width: '33%', margin: '8px' }}>
                    <SearchBar value={searchText} onKeyUp={setSearchText} onReset={() => { setSearchText(undefined) }} />
                    <div style={{height:'calc(100vh - 200px)',overflowY:'auto'}}>
                        
                    {filteredTasks.map((task, index) => {
                        return (
                            <div key={index} style={{ width: 'auto', padding: '8px', margin: '8px', backgroundColor: '#f7f7f7', border: '1px solid #f7f7f7', cursor: 'pointer', borderLeft: (selectedTask.number == task.number) ? '2px solid #00006a' : '2px solid #f7f7f7' }} onClick={() => { setSelectedTask(task); setSelectedProcess(null) }}>
                                {task.number}  {task.name}<br />
                            </div>
                        )
                    })}
                    </div>
                </Paper>
                <Paper style={{ width: '67%', margin: '8px', padding: '8px' }}>
                    <Typography component="h2" >{selectedTask.number}</Typography><br />
                    <Tabs value={selectedView} onChange={setSelectedView}>
                        <div >
                            TAAKOMSCHRIJVING
                        </div>
                        <div>
                            FUNCTIES
                        </div>
                        {selectedFunction && <div>{selectedFunction.name.toUpperCase()}</div>}
                    </Tabs>
                    {selectedView === 0 && <ViewTaskGeneral task={selectedTask} rasci={rasci} />}

                    {selectedView === 1 && <ViewTasksFunctions task={selectedTask} rasci={rasci} onSelectedFunction={(id)=>{setSelectedFunction(getFunction(id));setSelectedView(2)}}/>}

                    {selectedView === 2 && selectedFunction && <Button onClick={()=>{  history && fileID && history.push(`/rasci/roles/${fileID}/${selectedFunction._id}`); }} style={{float:'right'}} variant="text">Bekijk Rollen / taakomschrijving </Button>}

                    {selectedView === 2 && selectedFunction && <div style={{clear:'both'}} ><ViewRolesGeneral funct={selectedFunction} rasci={rasci} /></div>}


                </Paper>
            </div>
        </div>
    );
};

export default ViewTasks;