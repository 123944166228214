import React,{useEffect, useState} from 'react';

const ViewTaskGeneral = ({ task, rasci }) => {
 
    useEffect(() => {
        if (task && rasci) {
        
        }
    }, [task])
    if (!task) return null;
    return (
        <div className='padding'>   
         {task.name} <br/><br/>
        <div className='label'>Beschrijving</div>
            {task.description} <br/><br/>
            <div className='label'>Verwijzing</div>
            {task.reference} <br/><br/>
           

        </div>
    );
};

export default ViewTaskGeneral;