import React,{useEffect, useState} from 'react';
import { Paper } from '../../ui/paper';
import TabsProcessesShort from './tabs-processes-short';
const ViewTasksFunctions = ({ task, rasci ,onSelectedFunction}) => {
  
    let arrRasci = ["R - Responsible", "A - Accountable", "S - Support", "C - Consult", "I - Informed"];
 

  
  
    return (
        <div className='padding'>
           
            {arrRasci.map((rasciItem, index) => {
                
                return <Paper key={rasciItem} className='padding' style={{marginBottom:'6px'}}>
                    <b>{rasciItem}</b><br /><br />
                    {rasci.linkedActivities.filter((la) => la.taskID === task._id && la.rasci === rasciItem[0]).map((la, index) => {
                        
                        let foundFunction = rasci.functions.find((p) => p._id === la.functionID);
                       
                        if (!foundFunction) return null;
                        return (
                            <div key={la._id} style={{paddingTop:'6px',cursor:'pointer'}} onClick={()=>{onSelectedFunction && onSelectedFunction(foundFunction._id)}}> {foundFunction.number} {foundFunction.name}</div>
                        )
                    })}
                </Paper>
            })}
        </div>
    );
};

export default ViewTasksFunctions;