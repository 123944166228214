import React, { useState } from 'react';
import ServiceRasci from '../../../services-admin/rasci';
import ServiceGeneral from '../../../services/general';
import { Button } from '../../ui/button';
import { List } from '../../ui/list';
import { ListItem } from '../../ui/listItem';
import { ListItemText } from '../../ui/listItemText';
import { Dialog } from '../../ui/dialog';
import { DialogTitle } from '../../ui/dialogTitle';
import { DialogContent } from '../../ui/dialogContent';
import { DialogActions } from '../../ui/dialogActions';
import { Typography } from '../../ui/typography';
import { TextField } from '../../ui/textField';
import { TextArea } from '../../ui/textArea';
import { Select } from '../../ui/select';
import { Delete } from '../../ui/coreIcons';
import { IconButton } from '../../ui/iconButton';
const EditFunctions = ({ rasciID }) => {
    const table = 'functions';
    const [items, setItems] = useState([]);
    const [editItem, setEditItem] = useState();
    const [organisations, setOrganisations] = useState([]);
    const [educations, setEducations] = useState([]);
    const [meetings, setMeetings] = useState([]);

    const loadItems = () => {
        ServiceRasci.get(rasciID, table)
            .then((result) => {

                setItems(ServiceGeneral.sortJSON(result, 'number', '123'));
            }, (err) => {
                console.log(err);
            })
        ServiceRasci.get(rasciID, 'organisations')
            .then((result) => {

                setOrganisations(ServiceGeneral.sortJSON(result, 'number', '123'));
            }, (err) => {
                console.log(err);
            })
        ServiceRasci.get(rasciID, 'educations')
            .then((result) => {

                setEducations(ServiceGeneral.sortJSON(result, 'number', '123'));
            }, (err) => {
                console.log(err);
            })
        ServiceRasci.get(rasciID, 'meetings')
            .then((result) => {

                setMeetings(ServiceGeneral.sortJSON(result, 'number', '123'));
            }, (err) => {
                console.log(err);
            })
    }

    const handleDelete = () => {
        if (window.confirm('Weet je zeker dat je deze functie wilt verwijderen.')) {
            ServiceRasci.update(rasciID, table, { ...editItem, active: false })
                .then((result) => {
                    loadItems();
                    setEditItem(null);
                });
        }
    }

    const handleAddItem = () => {
        ServiceRasci.update(rasciID, table, { rasciID: rasciID, name: 'Nieuwe functie', active: true }).
            then((result) => {
                setEditItem(result[0]);
                loadItems();
            }, (err) => {
                console.log(err);
            })
    }

    useState(() => {
        loadItems();
    }, [rasciID]);

    const handleChange = (attribute, value, save) => {
        setEditItem({ ...editItem, [attribute]: value });
        if (save) handleSave({ ...editItem, [attribute]: value });
    }

    const handleSave = (item) => {
        ServiceRasci.update(rasciID, table, item)
            .then((result) => {
                loadItems();
            });

    }

    const handleAddArrItem = (attribute, value) => {
        let tmpEditItem = Object.assign({}, editItem);
        if (!tmpEditItem[attribute] || typeof tmpEditItem[attribute] == 'string') tmpEditItem[attribute] = [];
        tmpEditItem[attribute] = tmpEditItem[attribute].filter((item) => { return item !== value });
        tmpEditItem[attribute].push(value);
        setEditItem(tmpEditItem);
        handleSave(tmpEditItem);
    }
    const handleDeleteArrItem = (attribute, value) => {
       if( window.confirm("Weet u zeker dat u dit item wilt verwijderen?")){
        let tmpEditItem = Object.assign({}, editItem);
        tmpEditItem[attribute] = tmpEditItem[attribute].filter((item) => { return item !== value });
        setEditItem(tmpEditItem);
        handleSave(tmpEditItem);
       }
    } 
    return (
        <div>
            {editItem && <Dialog fullScreen onClose={() => { }} className="padding-ios">
                <DialogTitle><Typography component="h2">Functie</Typography></DialogTitle>
                <DialogContent >
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <TextField id="number" value={editItem.number} label="Nummer " onChange={(ev) => handleChange('number', ev.target.value)} onBlur={() => handleSave(editItem)} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <TextField id="name" value={editItem.name} label="Naamgeving" onChange={(ev) => handleChange('name', ev.target.value)} onBlur={() => handleSave(editItem)} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <Select value={editItem.organisation} label="Organisatie" onChange={(ev) => { handleChange('organisation', ev.target.value, true) }}>
                                <option value={undefined}>-</option>
                                {organisations.map((item) => { return <option key={item._id} value={item._id}>{(item.number || '') + ' ' + item.name}</option> })}
                            </Select>
                        </div>
                    </div>
                    <TextArea id="targets" value={editItem.targets} label="Doelstelling" onChange={(ev) => handleChange('targets', ev.target.value)} onBlur={() => handleSave(editItem)} />
                    <TextArea id="frame" value={editItem.frame} label="Kader" onChange={(ev) => handleChange('frame', ev.target.value)} onBlur={() => handleSave(editItem)} />
                    <TextArea id="profile" value={editItem.profile} label="Profiel" onChange={(ev) => handleChange('profile', ev.target.value)} onBlur={() => handleSave(editItem)} />
                    <TextArea id="contacts" value={editItem.contacts} label="Contacten" onChange={(ev) => handleChange('contacts', ev.target.value)} onBlur={() => handleSave(editItem)} />
                    <br /> <br />
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <div className='label'>Opleidingen</div>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                {editItem.educations && editItem.educations.map && editItem.educations.map((item) => {
                                let education = educations.find((edu) => { return edu._id === item });
                              
                                if (!education) return null;
                                return <tr key={item} >
                                    <td  >
                                        {education.number}
                                    </td>
                                    <td  >
                                        {education.name}
                                    </td>
                                    <td>
                                        <IconButton onClick={() => { handleDeleteArrItem('educations', education._id) }}><Delete ></Delete></IconButton>
                                    </td>
                                </tr>
                            })}
                                </tbody>
                            </table>
                           
                            <select  label="Toevoegen opleiding" onChange={(ev) => { handleAddArrItem('educations', ev.target.value, true) }}>
                                <option value={undefined}>Selecteer een opleiding om toe te voegen</option>
                                {educations.map((item) => { return <option key={item._id} value={item._id}>{(item.number || '') + ' ' + item.name}</option> })}
                            </select>
                        </div>
                        <div style={{ width: '100%' }}>
                        <div className='label'>Overleggen</div>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                {editItem.meetings && editItem.meetings.map((item) => {
                                let meeting = meetings.find((me) => { return me._id === item });
                              
                                if (!meeting) return null;
                                return <tr key={item} >
                                    <td  >
                                        {meeting.number}
                                    </td>
                                    <td  >
                                        {meeting.name}
                                    </td>
                                    <td>
                                        <IconButton onClick={() => { handleDeleteArrItem('meetings', meeting._id) }}><Delete ></Delete></IconButton>
                                    </td>
                                </tr>
                            })}
                                </tbody>
                            </table>
                           
                            <select  label="Toevoegen overleg" onChange={(ev) => { handleAddArrItem('meetings', ev.target.value, true) }}>
                                <option value={undefined}>Selecteer een overleg om toe te voegen</option>
                                {meetings.map((item) => { return <option key={item._id} value={item._id}>{(item.number || '') + ' ' + item.name}</option> })}
                            </select>
                         </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" style={{ color: 'red', float: 'left' }} onClick={() => { handleDelete() }}>Verwijderen</Button>
                    <Button variant="outlined" onClick={() => { setEditItem(null) }}>Sluiten</Button>
                </DialogActions>
            </Dialog>}

            <Button style={{ float: 'right' }} onClick={handleAddItem}>TOEVOEGEN</Button>
            <List>
                {items.map((item) => {
                    return (
                        <ListItem key={item._id} onClick={() => { setEditItem(item) }} compact={true}>
                            <ListItemText primary={(item.number || '') + ' ' + item.name} />
                        </ListItem>
                    )
                })
                }
            </List>
        </div>
    );
};

export default EditFunctions;