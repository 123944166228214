import React, { useContext,useRef ,useEffect} from 'react'
import { ContextUI } from '../../uiContext';

const LinearProgress = ({value}) => {
    const contextUI = useContext(ContextUI);
    const refBar = useRef();
    const styleContainer = { backgroundColor: '#e3e3e3', width: '100%' ,marginTop:'4px' }
    const styleBar = { height: '6px',width: '10%', backgroundColor: contextUI.primary.color }

    const progress = () => {
        if (refBar.current) {
            let tmpVal = parseInt(refBar.current.style.width.replace('%',''));
            tmpVal = tmpVal +1;
            if(tmpVal>100){
                tmpVal = 0;
            }
            refBar.current.style.width = tmpVal + '%'
        }
        setTimeout(progress, 10);
    }
    
    useEffect(() => { 
        if(value !== undefined && refBar.current){
            refBar.current.style.width = value + '%'
        }else{
            progress();
        }
      
    }, [value])
   

    return (
        <div style={styleContainer}><div style={styleBar} val={10} ref={refBar}>&nbsp;</div></div>
    )
}
export { LinearProgress };
